import React, { useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import NavigationBar from "../Components/NavigationBar";
import Footer from "../Components/Footer";

import BarraLaterale from "../Components/BarraLaterale";
import Aos from "aos";
import "aos/dist/aos.css";
import "../Style/pageSecondary.css";

import bgValori from "../asset/valoriBG-80op.png";
import Excellent from "../asset/serviceExcellent.png";
import integrity from "../asset/integrity.png";
import responsability from "../asset/responsability.png";

import CallToActionNumberGreen from "../Components/CallToActionNumberGreen";
import LavorodiSquadra from "../asset/lavoroDiSquadra.png";
import fiducia from "../asset/fiduciaValori.png";
import { Helmet } from "react-helmet";

const LaNostraMission = () => {
  const MissionIntroPageStyle = {
    backgroundImage: `url(${bgValori})`, // Utilizza l'immagine di sfondo
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "50vh", // Imposta l'altezza minima al 100% della viewport
    display: "flex",
    backgroundColor: "white",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "3rem",
  };

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <NavigationBar />
      <Helmet>
        <title>11.22 sviluppo immobiliare-valori-aziendali</title>
        <meta
          name="description"
          content="11.22 Sviluppo Immobiliare Srl è una società
          basata su valori concreti, che mettono in primo piano i nostri investitori e collaboratori"
        />
        <link
          rel="canonical"
          href="https://www.1122sviluppoimmobiliare.com/valori"
        />
      </Helmet>

      <BarraLaterale />
      <Container
        fluid
        style={MissionIntroPageStyle}
        className="img-zoom overflowHidden"
      >
        <div
          className="mt-5 pt-5 d-flex flex-column justify-content-center
         align-items-center text-center fade-in h-100 "
        >
          <h1 className="mt-5 fw-bold red-logo ">I NOSTRI VALORI AZIENDALI</h1>
        </div>
      </Container>
      <Container fluid className="py-5 color-blunav overflowHidden">
        <p className=" p-4 fs-3 text-white  text-center  p-lg-5">
          <span className=" fw-bold fs-3 d-block">
            Per rendere profittevoli le operazioni immobiliari e finanziarie,
            non sono sufficienti la passione e le competenze. E’ necessario
            basare il proprio lavoro su valori profondi e radicati.
          </span>{" "}
          <hr />
          Questi sono i valori su cui si fonda la nostra società e che ci
          impegniamo quotidianamente a trasmettere ai nostri collaboratori e
          partner.
        </p>
      </Container>
      <Container fluid className="overflowHidden bgBluOpacity05  shadow py-4">
        <Container>
          <Row className=" d-flex align-items-center justify-content-start ">
            <Col
              className="text-white text-center d-flex flex-column justify-content-start align-items-center mb-5  p-4 rounded "
              lg={6}
              md={12}
              sm={12}
              xs={12}
              data-aos="zoom-in"
              data-aos-offset="200"
              data-aos-duration="2000"
            >
              <div className="d-flex justify-content-center align-items-center flex-column w-100">
                <h2 className="text-center bold-900  fs-1 mb-4 red-logo">
                  Integrità
                </h2>
                <p className="colortext-blunav fw-bold fs-5 w-100 .line-height3">
                  Crediamo che nulla sia più importante della nostra reputazione
                  e comportarci con i più alti livelli di integrità è
                  fondamentale per quello che siamo, e per la nostra crescita.
                </p>
              </div>
            </Col>
            <Col
              lg={6}
              md={12}
              sm={12}
              xs={12}
              className="rounded"
              data-aos="fade-up-left"
              data-aos-duration="1500"
            ></Col>

            <Col
              lg={6}
              md={12}
              sm={12}
              xs={12}
              className="p-5"
              data-aos="fade-right"
              data-aos-duration="3000"
            >
              <img
                src={integrity}
                className="w-100 radius10  shadow"
                alt="img general contractor"
              />
            </Col>
            <Col
              className="text-white text-center d-flex flex-column justify-content-start align-items-center mb-5  p-4 rounded "
              lg={6}
              md={12}
              sm={12}
              xs={12}
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              <div className="d-flex justify-content-center align-items-center flex-column w-100">
                <h2 className="text-center bold-900  fs-1 mb-4 red-logo">
                  Successo dei nostri investitori
                </h2>
                <p className="colortext-blunav fw-bold fs-5 w-100">
                  Sosteniamo i nostri investitori garantendo loro una rendita
                  profittevole, per raggiungere un obiettivo comune. Il loro
                  successo è il nostro successo.
                </p>
              </div>
            </Col>

            <Col
              className="text-white text-center d-flex flex-column justify-content-start align-items-center mb-5  p-4 rounded "
              lg={6}
              md={12}
              sm={12}
              xs={12}
              data-aos="zoom-in"
              data-aos-offset="300"
              data-aos-duration="2000"
            >
              <div className="d-flex justify-content-center align-items-center flex-column w-100">
                <h2 className="text-center bold-900  fs-1 mb-4 red-logo">
                  Competenza approfondita
                </h2>
                <p className="colortext-blunav fw-bold fs-5 w-100">
                  Ci impegniamo a trasformare la conoscenza in intuizione, e
                  stare al passo con i tempi è il modo in cui generiamo valore
                  per i nostri investitori.
                </p>
              </div>
            </Col>
            <Col
              lg={6}
              md={12}
              sm={12}
              xs={12}
              className="rounded"
              data-aos="fade-up-left"
              data-aos-offset="300"
              data-aos-duration="1500"
            ></Col>
          </Row>
        </Container>
      </Container>
      {/* __________________ */}
      <Container fluid className="overflowHidden   shadow py-4">
        <Container>
          <Row className=" d-flex align-items-center justify-content-start ">
            <Col
              lg={6}
              md={12}
              sm={12}
              xs={12}
              className="  p-5"
              data-aos="fade-right"
              data-aos-duration="3000"
            >
              <img
                src={Excellent}
                className="w-100 radius10  shadow"
                alt="img general contractor"
              />
            </Col>
            <Col
              className="text-white text-center d-flex flex-column justify-content-start align-items-center mb-5  p-4 rounded "
              lg={6}
              md={12}
              sm={12}
              xs={12}
              data-aos="zoom-in"
              data-aos-offset="200"
              data-aos-duration="2000"
            >
              <div className="d-flex justify-content-center align-items-center flex-column w-100 ">
                <h2 className="text-center bold-900  fs-1 mb-4 red-logo">
                  Eccellenza nel servizio
                </h2>
                <p className="colortext-blunav fw-bold fs-5 w-100 .line-height3">
                  La trasparenza del nostro operato e la forza delle relazioni
                  con i nostri investitori sono la chiave per offrire un
                  servizio caratterizzato da un elevato standard qualitativo.
                </p>
              </div>
            </Col>

            <Col
              className="text-white text-center d-flex flex-column justify-content-start align-items-center mb-5  p-4 rounded "
              lg={6}
              md={12}
              sm={12}
              xs={12}
              data-aos="zoom-in"
              data-aos-offset="300"
              data-aos-duration="2000"
            >
              <div className="d-flex justify-content-center align-items-center flex-column w-100">
                <h2 className="text-center bold-900  fs-1 mb-4 red-logo">
                  Responsabilità sociale
                </h2>
                <p className="colortext-blunav fw-bold fs-5 w-100">
                  Crediamo fortemente che ogni intervento nel settore
                  immobiliare debba sempre rispettare i criteri di sostenibilità
                  ambientale e attenzione al benessere collettivo.
                </p>
              </div>
            </Col>
            <Col
              lg={6}
              md={12}
              sm={12}
              xs={12}
              className="rounded p-5"
              data-aos="fade-up-left"
              data-aos-duration="1500"
            >
              <img
                src={responsability}
                className="w-100 radius10  shadow"
                alt="img general contractor"
              />
            </Col>
          </Row>
        </Container>
      </Container>
      {/*  ________________________ */}

      <Container fluid className="overflowHidden bgBluOpacity05  shadow py-4">
        <Container>
          <Row className=" d-flex align-items-center justify-content-start ">
            <Col
              className="text-white text-center d-flex flex-column justify-content-start align-items-center mb-5  p-4 rounded "
              lg={6}
              md={12}
              sm={12}
              xs={12}
              data-aos="zoom-in"
              data-aos-offset="200"
              data-aos-duration="2000"
            >
              <div className="d-flex justify-content-center align-items-center flex-column w-100">
                <h2 className="text-center bold-900  fs-1 mb-4 red-logo">
                  Fiducia
                </h2>
                <p className="colortext-blunav fw-bold fs-5 w-100 .line-height3">
                  Guadagniamo la fiducia dei nostri investitori attraverso un
                  servizio caratterizzato da trasparenza, sicurezza e privacy.
                </p>
              </div>
            </Col>
            <Col
              lg={6}
              md={12}
              sm={12}
              xs={12}
              className="rounded p-5"
              data-aos="fade-up-left"
              data-aos-duration="1500"
            >
              {" "}
              <img
                src={fiducia}
                className="w-100 radius10  shadow"
                alt="img general contractor"
              />
            </Col>

            <Col
              lg={6}
              md={12}
              sm={12}
              xs={12}
              className="p-5"
              data-aos="fade-right"
              data-aos-duration="3000"
            >
              <img
                src={LavorodiSquadra}
                className="w-100 radius10  shadow"
                alt="img general contractor"
              />
            </Col>
            <Col
              className="text-white text-center d-flex flex-column justify-content-start align-items-center mb-5  p-4 rounded "
              lg={6}
              md={12}
              sm={12}
              xs={12}
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              <div className="d-flex justify-content-center align-items-center flex-column w-100">
                <h2 className="text-center bold-900  fs-1 mb-4 red-logo">
                  Uguaglianza e pari opportunità
                </h2>
                <p className="colortext-blunav fw-bold fs-5 w-100">
                  Crediamo che l’esperienza e le idee di persone diverse possano
                  rappresentare un’opportunità di crescita reciproca; valutare
                  prospettive differenti accresce l’innovazione, approfondisce
                  le connessioni tra le persone e rende la nostra azienda
                  migliore.
                </p>
              </div>
            </Col>

            <Col
              className="text-white text-center d-flex flex-column justify-content-start align-items-center mb-5  p-4 rounded "
              lg={6}
              md={12}
              sm={12}
              xs={12}
              data-aos="zoom-in"
              data-aos-offset="300"
              data-aos-duration="2000"
            >
              <div className="d-flex justify-content-center align-items-center flex-column w-100">
                <h2 className="text-center bold-900  fs-1 mb-4 red-logo">
                  Lavoro di squadra
                </h2>
                <p className="colortext-blunav fw-bold fs-5 w-100">
                  Siamo certi che in tutto il processo legato allo sviluppo e
                  alla realizzazione dell’investimento immobiliare la
                  collaborazione tra vari professionisti, ciascuno con le
                  proprie capacità e competenze, e il lavoro di squadra siano le
                  componenti essenziali per un team di successo.
                </p>
              </div>
            </Col>
            <Col
              lg={6}
              md={12}
              sm={12}
              xs={12}
              className="rounded"
              data-aos="fade-up-left"
              data-aos-offset="300"
              data-aos-duration="1500"
            ></Col>
          </Row>
        </Container>
      </Container>

      <CallToActionNumberGreen />

      <Footer />
    </>
  );
};
export default LaNostraMission;
