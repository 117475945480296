import React, { useState } from "react";
import { Card, Col, Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import { GiPositionMarker } from "react-icons/gi";
import { LiaBirthdayCakeSolid } from "react-icons/lia";
import { MdEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import { BiSolidCity } from "react-icons/bi";
import { PiGenderIntersexFill } from "react-icons/pi";
import Aos from "aos";
import "aos/dist/aos.css";

import "../../Style/pageSecondary.css";

const SingleUser = ({ user }) => {
  // Funzione per formattare la data
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <Col lg={4} md={6} sm={12} xs={12} className="fade-in-element ">
      <Card className="w-100 mb-4 shadow colortext-blunav fade-in">
        <div className="d-flex justify-content-center align-items-center my-2">
          <Card.Img className="imgSingleUserGestion " src={user.avatar} />
        </div>

        <Card.Body>
          <Card.Title className="text-center fw-bold mb-1">
            {user.name} {user.surname}
          </Card.Title>
          <Card.Text className="text-center red-logo">{user.role}</Card.Text>
          <Card.Text className="d-flex flex-column justify-content-around align-items-start gap-2">
            <div className="d-flex justify-content-center align-items-center gap-2">
              <span>ID: {user._id}</span>
            </div>
            <div className="d-flex justify-content-center align-items-center gap-2">
              <GiPositionMarker /> {user.address}
            </div>
            <div className="d-flex justify-content-center align-items-center gap-2">
              <BiSolidCity /> {user.city}
            </div>
            <div className="d-flex justify-content-center align-items-center gap-2">
              <PiGenderIntersexFill /> {user.gender}
            </div>

            <div className="d-flex justify-content-center align-items-center gap-2">
              <LiaBirthdayCakeSolid /> {formatDate(user.birthdayDate)}
            </div>
          </Card.Text>
          <Card.Text className="d-flex flex-column justify-content-around align-items-start gap-2">
            <div className="d-flex justify-content-center align-items-center gap-2">
              <MdEmail /> {user.email}
            </div>
            <div className="d-flex justify-content-center align-items-center gap-2">
              <FaPhone />
              <span>+ {user.phoneNumber} </span>
            </div>
          </Card.Text>
        </Card.Body>
        <Card.Footer>
          <Accordion defaultActiveKey="0" flush>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Domande Aggiuntive</Accordion.Header>
              <Accordion.Body className="zindexAccordion">
                <div
                  className="d-flex flex-column  flex-justify-content-center align-items-start gap-2"
                  id="example-fade-text"
                >
                  <p>
                    <span className="fw-bold me-2">
                      Esperienza investimenti:
                    </span>{" "}
                    {user.experienceInvestiment}
                  </p>
                  <p>
                    <span className="fw-bold me-2">
                      Motivazione investimento:
                    </span>{" "}
                    {user.motivationToInvestiment}
                  </p>
                  <p>
                    <span className="fw-bold me-2">
                      Come ci hai conosciuti:
                    </span>{" "}
                    {user.contactSociety}
                  </p>
                  <p>
                    <span className="fw-bold me-2">
                      Capitale a disposizione:
                    </span>{" "}
                    {user.dispositionCapital} €
                  </p>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Card.Footer>
      </Card>
    </Col>
  );
};

export default SingleUser;
