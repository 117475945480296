import React, { useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import "../Style/chiSiamo.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link, useNavigate } from "react-router-dom";
import ChiSiamoImg from "../asset/chiSiamo-img.png";
import IconShooting from "../asset/Shooting.png";
import IconAcquisition from "../asset/Acquisizione.png";
import IconContractor2 from "../asset/icona.nuova-contractor.png";
import IconExit from "../asset/Exit.png";

const ChiSiamo = () => {
  const navigate = useNavigate();

  const redirectChiSiamoOnClick = () => {
    navigate("/chiSiamo");
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <Container>
        <Row className="mt-5">
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className="d-flex flex-column justify-content-center align-items-center"
            data-aos="fade-up"
            data-aos-duration="3000"
            data-aos-offset="200"
          >
            {" "}
            <img
              src={ChiSiamoImg}
              alt="img presentazione"
              className="rounded w-100 mb-4 "
            />
          </Col>
          <Col
            className="text-white d-flex flex-column justify-content-center align-items-center "
            lg={6}
            md={12}
            sm={12}
            xs={12}
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1500"
            data-aos-offset="200"
          >
            <h1 className=" fs-1 text-center red-logo fw-bold">Chi Siamo</h1>
            <p className="mt-3 line-height3 fs-5 text-center colortext-blunav fw-bold ">
              11.22 Sviluppo Immobiliare Srl è una società innovativa di
              investimento attiva nel Real Estate a 360°, che propone operazioni
              profittevoli in maniera completamente passiva, specializzata
              prevalentemente in operazioni di compravendita nel settore
              residenziale e commerciale.
            </p>
            <Button
              onClick={redirectChiSiamoOnClick}
              className="button-presentation fs-5 my-3"
            >
              Scopri di più
            </Button>
          </Col>
        </Row>
      </Container>
      <Container fluid className="text-center container-style p-5 rounded mt-5">
        <div data-aos="zoom-in-down" data-aos-duration="1000">
          <h2 className="fs-2 fw-bold fst-italic red-logo">
            DI COSA CI OCCUPIAMO
          </h2>

          <p className=" mx-auto mt-3 fs-3 line-height2 width-100Smarphone width-70Pc colortext-blunav">
            Il nostro core <span className=" fw-bold">Businnes</span> è
            acquistare <span className=" fw-bold">Asset</span> immobiliari,
            valorizzarli e generare importanti rendimenti per i nostri
            investitori e partner
          </p>
        </div>
      </Container>

      <Container fluid className="pt-5 color-blunav">
        <Row className="d-flex justify-content-start align-items-start overflow-hidden px-4">
          <Col
            className="text-white text-center d-flex flex-column justify-content-center align-items-center mb-5"
            lg={3}
            md={6}
            sm={12}
            xs={12}
            data-aos="fade-left"
            data-aos-easing="linear"
            data-aos-duration="1500"
          >
            <img className="w-70 p-3  " src={IconShooting} alt="" />
            <h4 className="text-center fw-bold color-title-card fs-2">
              Scouting
            </h4>
            <p className="px-4 fs-5">
              Individuiamo Asset immobiliari estremamente efficienti sotto il
              profilo del rapporto rischio rendimento
            </p>
          </Col>

          <Col
            className="text-white text-center d-flex flex-column justify-content-center align-items-center mb-5"
            lg={3}
            md={6}
            sm={12}
            xs={12}
            data-aos="fade-left"
            data-aos-easing="linear"
            data-aos-duration="1500"
          >
            <img className="w-70 p-3 " src={IconAcquisition} alt="" />
            <h4 className="text-center fw-bold color-title-card fs-2">
              Acquisizione
            </h4>
            <ul className="px-4 fs-5">
              <p>Operiamo in 2 macro aree:</p>
              <li>Acquisizione di immobili e terreni nel mercato libero</li>
              <li>Acquisizione di immobili e terreni all'asta</li>
            </ul>
          </Col>

          <Col
            className="text-white text-center d-flex flex-column justify-content-center align-items-center mb-5"
            lg={3}
            md={6}
            sm={12}
            xs={12}
            data-aos="fade-left"
            data-aos-easing="linear"
            data-aos-duration="1500"
          >
            <img className="w-70 p-3 " src={IconContractor2} alt="" />
            <h4 className="text-center fw-bold color-title-card fs-2">
              General Contractor
            </h4>
            <p className="px-4 fs-5">
              Interveniamo direttamente nella riprogettazione e riqualificazione
              attraverso opere di ristrutturazione e costruzione
            </p>
          </Col>

          <Col
            className="text-white text-center d-flex flex-column justify-content-center align-items-center mb-5"
            lg={3}
            md={6}
            sm={12}
            xs={12}
            data-aos="fade-left"
            data-aos-easing="linear"
            data-aos-duration="1500"
          >
            <img className="w-70 p-3 " src={IconExit} alt="" />
            <p className="px-4 fs-5 ">
              <h4 className="text-center fw-bold color-title-card fs-2">
                Exit
              </h4>
              Riposizioniamo sul mercato gli immobili rivalutati generando per i
              nostri investitori un profittevole rendimento
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ChiSiamo;
